<template>
  <div class="mt-2">
    <v-card-text>
      <v-row class="mx-0">
        <v-row>
          <v-col cols="12" sm="12" lg="3" md="3">
            <span class="green--text">Categorías de consulta</span>

            <div class="container-filter">
              <v-icon color="grey">mdi-format-list-bulleted</v-icon>
              <v-autocomplete
                :items="categories"
                item-text="name"
                item-value="id"
                single
                label="Categorías"
                v-model="filters.category_id"
              ></v-autocomplete>
            </div>
          </v-col>
          <v-col cols="12" sm="12" lg="3" md="3">
            <span class="green--text">Rango fecha de consulta - Inicio</span>

            <v-menu
              ref="minDatePicker"
              v-model="minDatePicker"
              :close-on-content-click="false"
              :return-value.sync="filters.min_date"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="filters.min_date"
                  label="Rango de fecha"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filters.min_date"
                no-title
                scrollable
                :max="filters.max_date"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="minDatePicker = false">
                  Cancelar
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.minDatePicker.save(filters.min_date)"
                >
                  Aplicar
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="12" lg="3" md="3">
            <span class="green--text">Rango fecha de consulta - Fin</span>

            <v-menu
              ref="maxDatePicker"
              v-model="maxDatePicker"
              :close-on-content-click="false"
              :return-value.sync="filters.max_date"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="filters.max_date"
                  label="Rango de fecha"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filters.max_date"
                no-title
                scrollable
                :min="filters.min_date"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="maxDatePicker = false">
                  Cancelar
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.maxDatePicker.save(filters.max_date)"
                >
                  Aplicar
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-row>
    </v-card-text>

    <v-card-text>
      <v-row>
        <v-col cols="12" sm="12" lg="12" md="12">
          <span class="green--text">Filtrar por</span>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="12" lg="2" md="2">
          <div class="container-filter">
            <v-icon color="grey">mdi-map-marker-outline</v-icon>
            <v-autocomplete
              :items="territories"
              item-text="descripcion"
              item-value="id"
              single
              label="Territorio"
              v-model="filters.territory_id"
            ></v-autocomplete>
          </div>
        </v-col>
        <v-col cols="12" sm="12" lg="2" md="2">
          <div class="container-filter">
            <v-icon color="grey">mdi-map-marker-outline</v-icon>
            <v-autocomplete
              :items="entityresponsilities"
              item-text="name"
              item-value="id"
              single
              label="Sistemas reponsables"
              v-model="filters.entityresponsible_id"
            ></v-autocomplete>
          </div>
        </v-col>
        <v-col cols="12" sm="12" lg="2" md="2">
          <div class="container-filter">
            <v-icon color="grey">mdi-map-marker-outline</v-icon>
            <v-autocomplete
              :items="fundingsources"
              item-text="name"
              item-value="id"
              single
              label="Fuentes de financiación"
              v-model="filters.fundingsource_id"
            ></v-autocomplete>
          </div>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-text>
      <v-row class="mx-0">
        <v-row>
          <v-col
            cols="4"
            sm="4"
            lg="4"
            md="4"
            offset-md="4"
            class="d-flex flex-column justify-center"
          >
            <div class="d-flex justify-center" style="">
              <v-btn
                rounded
                color="primary"
                dark
                class="mb-5"
                @click="generateReports()"
              >
                GENERAR REPORTE <v-icon>mdi-chart-line</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-row>
    </v-card-text>

    <v-card-text>
      <div class="d-flex align-center justify-md-space-between">
        <h3 class="title blue-grey--text text--darken-2 font-weight-regular">
          LISTADO DE PLANES
        </h3>
        <v-btn
          rounded
          color="primary"
          dark
          class="mb-5"
          @click="donwloadListCase()"
        >
          EXPORTAR A EXCEL <v-icon>mdi-chart-line</v-icon>
        </v-btn>
      </div>
    </v-card-text>
    <v-data-table
      v-if="headers.length"
      :footer-props="{
        'items-per-page-text': 'Filas por página',
        'items-per-page-options': [5,10,15]

      }"
      :headers="headers"
      :items="dataTable"
      :items-per-page="15"
      :search="search"
      item-key="id"
    >
      <template v-slot:item.category_name="{ item }">
        <v-chip
          v-for="(category, index) in item.category_name.split(',')"
          color="#D3E9D7"
          :key="index"
          style="height: auto; margin-top: 11px; margin-left: 5px"
        >
          <span style="color: #222222; font-size: 12px">{{ category }}</span>
        </v-chip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import sweetalert from "sweetalert2";
import {
  listCases,
  listCasesDonwload,
  parameterization,
} from "../../../../services/graphicReports/planningService";

export default {
  props: ["queryStats"],
  data() {
    return {
      event_types: [],
      departaments: [],
      zones: [],
      municipalities: [],
      territories: [],
      sidewalks: [],
      entities: [],
      entitytrackingresponse: [],
      states: [],
      dataTable: [],
      search: "",
      headers: [
        {
          text: "ID.",
          align: "start",
          sortable: true,
          value: "plan_id",
        },
        { text: "FECHA CREACIÓN", value: "plan_date" },
        { text: "TERRITORIO", value: "territory_description" },
        { text: "NIVEL DEL MANDATO", value: "mandate_name" },
        { text: "AÑO MANDATO", value: "plan_mandate_year" },
        { text: "CATEGORIAS", value: "category_name" },
        {
          text: "SIST. RESPONSABLE",
          align: "center",
          value: "entityresponsible_name",
        },
        {
          text: "FUENTE FINANCIACIÓN",
          align: "center",
          value: "fundingsource_name",
        },
        {
          text: "NÚM. ACCIONES",
          align: "center",
          value: "total_actions",
        },
        {
          text: "EST. COSTO",
          align: "center",
          value: "total_cost_actions",
        },
        {
          text: "SEGUIMIENTOS",
          align: "center",
          value: "tracking_total_tracking",
        },
        {
          text: "AVANCE DE EJECUCIÓN",
          align: "center",
          value: "advance_execution",
        },
      ],
      filters: {
        category_id: 0,
        territory_id: 0,
        entityresponsible_id: 0,
        fundingsource_id: 0,
        min_date: "",
        max_date: "",
      },
      minDatePicker: false,
      maxDatePicker: false,
    };
  },
  methods: {
    async fillData() {
      this.showLoader();
      const { data } = await parameterization(this.queryStats);
      this.categories = data.categories || [];
      this.territories = data.territories || [];
      this.entityresponsilities = data.entityresponsilities || [];
      this.fundingsources = data.fundingsources || [];

      this.hideLoader();
    },
    async generateReports() {
      this.showLoader();
      const { data = [] } = await listCases(this.filters);
      this.dataTable = data;
      this.hideLoader();
    },
    async donwloadListCase() {
      const { data = null } = await listCasesDonwload(this.filters);
      if (!data) return;

      var url = window.URL || window.webkitURL;
      const link = url.createObjectURL(data);
      var a = document.createElement("a");
      a.setAttribute("download", "Lista de casos.xlsx");
      a.setAttribute("href", link);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
  },
  async created() {
    try {
      await this.fillData();
    } catch (err) {
      this.hideLoader();
      sweetalert.fire(
        "Ups!",
        "ha ocurrido un error al procesar la solicitud",
        "error"
      );
    }
  },
};
</script>

<style scoped>
.container-filter {
  display: flex;
  align-items: center;
  gap: 5px;
}
</style>
