import baseService from "../baseService";
const resource = "/rest/v1";

export function casesByCategory(data) {
  return baseService.post(
    `${resource}/graphic_reports/planning/cases-by-category`,
    data
  );
}

export function casesByExecuteTerritory(data) {
    return baseService.post(
      `${resource}/graphic_reports/planning/cases-by-execute-territory`,
      data
    );
  }

export function casesByEntityResponsible(data) {
  return baseService.post(
    `${resource}/graphic_reports/planning/cases-by-entity-responsible`,
    data
  );
}

export function casesByFundingSources(data) {
  return baseService.post(
    `${resource}/graphic_reports/planning/cases-by-funding-sources`,
    data
  );
}

export function casesByMandate(data) {
  return baseService.post(
    `${resource}/graphic_reports/planning/cases-by-mandate`,
    data
  );
}

export function casesByLevelMandateTerritory(data) {
  return baseService.post(
    `${resource}/graphic_reports/planning/cases-by-level-mandate-territory`,
    data
  );
}

export function listCases(data) {
  return baseService.post(
    `${resource}/graphic_reports/planning/list-cases`,
    data
  );
}

export async function listCasesDonwload(data) {
  return baseService.post(
    `${resource}/graphic_reports/planning/list-cases-download`,
    data,
    {
      responseType: "blob",
    }
  );
}

export function parameterization() {
  return baseService.get(
    `${resource}/graphic_reports/planning/parameterization`
  );
}
