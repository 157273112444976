<template>
  <main>
    <div class="wrapper">
      <canvas id="casesByFundingSources"></canvas>
    </div>
  </main>
</template>
<script>
import Chart from "chart.js/auto";
import { casesByFundingSources } from "../../../../services/graphicReports/planningService";

export default {
  props: ["queryStats"],
  data() {
    return {
      reportData: [],
    };
  },
  methods: {
    async loadReportData() {
      const { data } = await casesByFundingSources(this.queryStats);
      this.reportData = data;

      this.$nextTick(() => this.renderBar());
    },

    renderBar() {
      const casesByFundingSources_pie = document.getElementById(
        "casesByFundingSources"
      );

      let label = [];
      let datos = [];

      this.reportData.forEach((item, position) => {
        label[position] = item.funding_source__name || "";
        datos[position] = item.total || 0;
      });

      new Chart(casesByFundingSources_pie, {
        type: "pie",
        data: {
          labels: label,
          datasets: [
            {
              label: "DATOS",
              data: datos,
              fill: false,
              backgroundColor: [
                "rgb(67, 121, 78, 1)",
                "rgb(81, 196, 129, 1)",
                "rgb(242, 151, 46, 1)",
                "rgb(4, 66, 115, 1)",
                "rgb(190, 195, 199, 1)",
              ],
              borderColor: "rgb(0, 128, 55,0.2)",
              pointBackgroundColor: "rgb(0, 128, 55)",
              pointBorderColor: "#fff",
              pointHoverBackgroundColor: "#fff",
              pointHoverBorderColor: "rgb(0, 128, 0)",
            },
          ],
        },
        options: {
          indexAxis: "y",
          responsive: true,
          maintainAspectRatio: false,
          elements: {
            line: {
              borderWidth: 2,
            },
          },
        },
      });
    },
  },
  beforeMount() {
    this.loadReportData();
  },
};
</script>
<style scoped>
.wrapper {
  width: 100%;
  height: 450px;
  max-height: 650px;
  text-align: center !important;
}
</style>
