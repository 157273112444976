<template>
  <main style="width: 100%; overflow-x: auto;">
    <div class="wrapper" :style="{width: reportData.length <= 10 ? '100%' : `${(reportData.length * 30)}px`}">
      <canvas id="casesByLevelMandateTerritory"></canvas>
    </div>
  </main>
</template>
<script>
import Chart from "chart.js/auto";
import { casesByLevelMandateTerritory } from "../../../../services/graphicReports/planningService";

export default {
  props: ["queryStats"],
  data() {
    return {
      reportData: [],
    };
  },
  methods: {
    async loadReportData() {
      const { data } = await casesByLevelMandateTerritory(this.queryStats);
      this.reportData = data;

      this.$nextTick(() => this.renderBar());
    },

    renderBar() {
      const casesByLevelMandateTerritory_bar = document.getElementById(
        "casesByLevelMandateTerritory"
      );

      new Chart(casesByLevelMandateTerritory_bar, {
        type: "bar",
        data: this.reportData,
        options: {
          indexAxis: "x",
          responsive: true,
          maintainAspectRatio: false,
          elements: {
            line: {
              borderWidth: 2,
            },
          },
        },
      });
    },
  },
  beforeMount() {
    this.loadReportData();
  },
};
</script>
<style scoped>
.wrapper {
  width: 100%;
  height: 450px;
  max-height: 650px;
  text-align: center !important;
}
</style>
