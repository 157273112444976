<template>
    <v-container fluid>
      <template>
        <v-breadcrumbs>
          <template>
            <v-breadcrumbs-item>
              <h3>REPORTES</h3>
            </v-breadcrumbs-item>
            <v-breadcrumbs-item>
              <v-icon color="primary">mdi-home</v-icon>
            </v-breadcrumbs-item>
            <v-breadcrumbs-item> Reportes </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </template>
  
      <template>
        <v-card class="overflow-hidden mt-2">
          <v-toolbar flat color="white">
            <v-toolbar-title class="font-weight-bold" color="grey darken-3">
                REPORTE GRÁFICO
            </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-divider></v-divider>
  
          <v-card-text>
            <v-row class="mx-0">
              <v-row>
                <v-col cols="12" sm="12" lg="3" md="3">
                  <span class="green--text"
                    >Años de consulta - Inicio</span
                  >
  
                  <v-menu
                    ref="minDatePicker"
                    v-model="minDatePicker"
                    :close-on-content-click="false"
                    :return-value.sync="filters.min_date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="filters.min_date"
                        label="Rango de fecha"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                        @click:clear="handleClearMinDate"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filters.min_date"
                      no-title
                      scrollable
                      :max="filters.max_date"
                      @change="changeDate"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="minDatePicker = false">
                        Cancelar
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.minDatePicker.save(filters.min_date)"
                      >
                        Aplicar
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="12" lg="3" md="3">
                  <span class="green--text">Años de consulta - Fin</span>
  
                  <v-menu
                    ref="maxDatePicker"
                    v-model="maxDatePicker"
                    :close-on-content-click="false"
                    :return-value.sync="filters.max_date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="filters.max_date"
                        label="Rango de fecha"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                        @click:clear="handleClearMaxDate"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filters.max_date"
                      no-title
                      scrollable
                      :min="filters.min_date"
                      @change="changeDate"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="maxDatePicker = false">
                        Cancelar
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.maxDatePicker.save(filters.max_date)"
                      >
                        Aplicar
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
  
      <template v-if="reloadAllReports && filters.min_date && filters.max_date">
  
        <v-card-text>
          <v-row class="mt-2">
            <v-col md="6" lg="6" sm="12" xs="12">
              <v-card>
                <v-card-text class="">
                    <div class="d-flex align-center justify-lg-space-between">
                      <h3
                        class="title blue-grey--text text--darken-2 font-weight-regular"
                      >
                        PLANES POR CATEGORÍAS
                      </h3>

                      <div class="container-filter">
                        <v-icon color="grey">mdi-map-marker-outline</v-icon>
                        <v-autocomplete
                        :items="[
                          {id: 0, descripcion: 'TODOS'},
                          ...territories
                        ]"
                        item-text="descripcion"
                        item-value="id"
                        single
                        label="Territorios"
                        v-model="filters.category_territory_id"
                        @change="changeSelect"
                        ></v-autocomplete>
                      </div>
                    </div>
                    <h6 class="subtitle-2 font-weight-light"></h6>
                  <div v-if="reloadCategoryReport" class="">
                    <cases-by-category :queryStats="filters"></cases-by-category>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col md="6" lg="6" sm="12" xs="12">
              <v-card>
                <v-card-text class="">
                  <div class="d-flex align-center justify-lg-space-between">
                      <h3
                        class="title blue-grey--text text--darken-2 font-weight-regular"
                      >
                        AVANCE DE EJECUCIÓN POR TERRITORIO
                      </h3>

                      <div class="container-filter">
                        <v-icon color="grey">mdi-map-marker-outline</v-icon>
                        <v-autocomplete
                        :items="[
                          {id: 0, descripcion: 'TODOS'},
                          ...territories
                        ]"
                        item-text="descripcion"
                        item-value="id"
                        single
                        label="Territorios"
                        v-model="filters.execute_territory_id"
                        @change="changeSelectExecuteTerritory"
                        ></v-autocomplete>
                      </div>
                    </div>
                  <h6 class="subtitle-2 font-weight-light"></h6>
                  <div v-if="reloadExecuteTerritory" class="">
                    <cases-by-execute-territory :queryStats="filters"></cases-by-execute-territory>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row class="mt-2">
            <v-col md="6" lg="6" sm="12" xs="12">
              <v-card>
                <v-card-text class="">
                  <h3
                    class="title blue-grey--text text--darken-2 font-weight-regular"
                  >
                    PLANES POR SISTEMAS
                  </h3>
                  <h6 class="subtitle-2 font-weight-light"></h6>
                  <div class="">
                    <cases-by-entity-responsible :queryStats="filters"></cases-by-entity-responsible>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col md="6" lg="6" sm="12" xs="12">
              <v-card>
                <v-card-text class="">
                  <h3
                    class="title blue-grey--text text--darken-2 font-weight-regular"
                  >
                    PLANES POR FUENTES DE FINANCIACIÓN
                  </h3>
                  <h6 class="subtitle-2 font-weight-light"></h6>
                  <div class="" max-height="100">
                    <cases-by-funding-sources :queryStats="filters"></cases-by-funding-sources>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row class="mt-2">
            <v-col md="6" lg="6" sm="12" xs="12">
              <v-card>
                <v-card-text class="">
                  <h3
                    class="title blue-grey--text text--darken-2 font-weight-regular"
                  >
                    PLANES POR NIVEL DE MANDATO
                  </h3>
                  <h6 class="subtitle-2 font-weight-light"></h6>
                  <div class="">
                    <cases-by-mandate :queryStats="filters"></cases-by-mandate>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col md="6" lg="6" sm="12" xs="12">
              <v-card>
                <v-card-text class="">
                  <h3
                    class="title blue-grey--text text--darken-2 font-weight-regular"
                  >
                    PLANES POR NIVEL DE MANDATO EN TERRITORIO
                  </h3>
                  <h6 class="subtitle-2 font-weight-light"></h6>
                  <div class="" max-height="100">
                    <cases-by-level-mandate-territory :queryStats="filters"></cases-by-level-mandate-territory>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </template>
      <template>
        <v-card class="overflow-hidden mt-2">
          <v-toolbar flat color="white">
            <v-toolbar-title class="font-weight-bold" color="grey darken-3">
              GENERAR REPORTE EXCEL
            </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-divider></v-divider>
          <v-card-text>
            <v-row class="mt-2">
                <v-col md="12" lg="12" sm="12" xs="12">
                <v-card>
                    <v-card-text class="">
                    <div class="" max-height="100">
                        <list-cases :queryStats="filters"></list-cases>
                    </div>
                    </v-card-text>
                </v-card>
                </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
    </v-container>
  </template>
  
  <script>
  import sweetalert from "sweetalert2";
  import { parameterization } from "../../../services/graphicReports/planningService";
  import CasesByCategory from "./reports/CasesByCategory.vue";
  import CasesByExecuteTerritory from "./reports/CasesByExecuteTerritory.vue";
  import CasesByEntityResponsible from './reports/CasesByEntityResponsible.vue';
  import CasesByFundingSources from './reports/CasesByFundingSources.vue';
  import CasesByMandate from './reports/CasesByMandate.vue';
  import CasesByLevelMandateTerritory from './reports/CasesByLevelMandateTerritory.vue';
  import ListCases from "./reports/ListCases.vue";
  
  export default {
    components: {
        CasesByCategory,
        CasesByExecuteTerritory,
        CasesByEntityResponsible,
        CasesByFundingSources,
        CasesByMandate,
        CasesByLevelMandateTerritory,
        ListCases
    },
    name: "graphicReports.vue",
    data() {
      return {
        filters: {
          min_date: "",
          max_date: "",
          execute_territory_id: 0,
          category_territory_id: 0,
        },
        minDatePicker: false,
        maxDatePicker: false,
        reloadAllReports: true,
        reloadCategoryReport: true,
        reloadExecuteTerritory: true
      };
    },
    methods: {
      async fillData() {
        this.showLoader();
        const { data } = await parameterization();
        this.territories = data.territories || [];

        this.hideLoader();
      },
      async changeSelect() {
        this.reloadCategoryReport = false;

        this.$nextTick(() => {
            this.reloadCategoryReport = true;
        })
      },
      async changeSelectExecuteTerritory() {
        this.reloadExecuteTerritory = false;

        this.$nextTick(() => {
            this.reloadExecuteTerritory = true;
        })
      },
      handleClearMinDate() {
        this.filters.min_date = '';
        this.changeDate(); 
      },
      handleClearMaxDate() {
        this.filters.max_date = '';
        this.changeDate(); 
      },
      async changeDate() {
        this.reloadAllReports = false;

        this.$nextTick(() => {
            this.reloadAllReports = true;
        })
      },
    },
    async created() {
      try {
        await this.fillData();
      } catch (err) {
        this.hideLoader();
        sweetalert.fire(
            "Ups!",
            "ha ocurrido un error al procesar la solicitud",
            "error"
        );
      }
    }
  };
  </script>
  
  <style scoped>
  .container-filter {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  </style>
  